/**
 * 铁轨
 */
export class Rail {
	constructor(sites, height) {
		const el = document.querySelector(".container");
		const frag = document.createDocumentFragment();
		sites.forEach((bottom) => {
			const div = document.createElement("div");
			div.classList.add("rail");
			div.style.cssText = `bottom: ${bottom}px;height:${height}px`;
			frag.appendChild(div);
		});
		el.appendChild(frag);
	}
}
