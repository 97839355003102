/**
 * 火车
 */
export class Train {
	constructor({ arrow = "left", site, headX, footX }) {
		const container = document.querySelector(".container");
		const frag = document.createDocumentFragment();
		if (arrow === "left") {
			const head = trainHead(site, headX);
			frag.appendChild(head);
			setTimeout(() => {
				const rect = head.getBoundingClientRect();
				console.log(rect);
				// const body = new TrainBody(site, headX + rect.width);
				// frag.appendChild(body.element);
				container.appendChild(frag);
			}, 1000);
			console.log(1);
		} else if (arrow === "right") {
			const foot = trainFoot(site, footX);
			frag.appendChild(foot);
			container.appendChild(frag);
		}
	}
}

/**
 * 火车车厢
 */
export class TrainBody {
	constructor(site, x) {
		console.log(site, x);
		const div = document.createElement("div");
		div.style.cssText = `bottom:${site}px;left:${x}px`;
		div.classList.add("train_body");
		this.element = div;
	}
}

/**
 * 火车头
 */
function trainHead(site, x) {
	const div = document.createElement("div");
	div.style.cssText = `bottom:${site}px;left:${x}px`;
	div.classList.add("train_head");
	return div;
}

/**
 * 火车尾
 */
function trainFoot(site, x) {
	const div = document.createElement("div");
	div.style.cssText = `bottom:${site}px;left:${x}px`;
	div.classList.add("train_foot");
	return div;
}
