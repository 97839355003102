import { Rail } from "./rail";
import { Train } from "./train";
export class Manager {
	constructor({ sites, railHeight = 15, headX = 34, footX = 0 }) {
		new Rail(sites, railHeight);
		sites.forEach((site, index) => {
			new Train({
				arrow: index === 0 ? "right" : "left",
				site: site + railHeight,
				headX,
				footX,
			});
		});
	}
}
